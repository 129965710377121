import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import '../generic-page.scss';
import './tier-list.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import lodash from 'lodash';
import {
  faAsterisk,
  faHandFist,
  faVirus,
  faFlask,
  faArrowUp,
  faArrowDown,
  faAnglesDown
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Card } from 'react-bootstrap';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { WWCharacter } from '../../modules/ww/common/components/ww-character';
import { WWRatings } from '../../modules/ww/data/ww-ratings';

const WWTierList: React.FC = () => {
  const [visibleCharacters, setVisibleCharacters] = useState(WWRatings);

  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search characters...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: '4★', value: '4' },
          { label: '5★', value: '5' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Aero',
            tooltip: 'Aero',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_aero.png"
                width={24}
                alt="Aero"
              />
            )
          },
          {
            value: 'Electro',
            tooltip: 'Electro',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_electro.png"
                width={24}
                alt="Electro"
              />
            )
          },
          {
            value: 'Fusion',
            tooltip: 'Fusion',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_fusion.png"
                width={24}
                alt="Fusion"
              />
            )
          },
          {
            value: 'Glacio',
            tooltip: 'Glacio',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_glacio.png"
                width={24}
                alt="Glacio"
              />
            )
          },
          {
            value: 'Havoc',
            tooltip: 'Havoc',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_havoc.png"
                width={24}
                alt="Havoc"
              />
            )
          },
          {
            value: 'Spectro',
            tooltip: 'Spectro',
            image: (
              <StaticImage
                src="../../images/ww/icons/element_spectro.png"
                width={24}
                alt="Spectro"
              />
            )
          }
        ]
      },
      {
        key: 'weapon',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Broadblade',
            tooltip: 'Broadblade',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_broadblade.png"
                width={24}
                alt="Broadblade"
              />
            )
          },
          {
            value: 'Gauntlets',
            tooltip: 'Gauntlets',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_gauntlets.png"
                width={24}
                alt="Gauntlets"
              />
            )
          },
          {
            value: 'Pistols',
            tooltip: 'Pistols',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_pistols.png"
                width={24}
                alt="Pistols"
              />
            )
          },
          {
            value: 'Rectifier',
            tooltip: 'Rectifier',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_rectifier.png"
                width={24}
                alt="Rectifier"
              />
            )
          },
          {
            value: 'Sword',
            tooltip: 'Sword',
            image: (
              <StaticImage
                src="../../images/ww/icons/weapon_sword.png"
                width={24}
                alt="Sword"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = WWRatings;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'id');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.rarity.toString() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
      if (activeFilters.weapon && activeFilters.weapon !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.weapon.toLowerCase() === activeFilters.weapon.toLowerCase()
        );
      }
    }

    setVisibleCharacters(filtered);
  }, [activeFilters, visibleCharacters, sortOption]);

  const sortingOptions = [
    { value: 'toa', label: 'Tower' },
    { value: 'ww', label: 'Whimpering' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }
  return (
    <DashboardLayout
      className={'generic-page wuthering ww-tier-page'}
      game="ww"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/ww/categories/category_tier.jpg"
            alt="Tier list"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Tier List</h1>
          <h2>
            The best tier list for Wuthering Waves that rates all available
            characters by their performance in the Tower of Adversity. Find the
            best characters in the game!
          </h2>
          <p>
            Last updated: <strong>28/03/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <div className="tier-list-page">
        <SectionHeader title="Wuthering Waves Tier list (2.2 Patch)" />
        <p className="intro">
          Please keep in mind Wuthering Waves is a{' '}
          <strong>
            skill-based game and each character has a different playstyle
          </strong>
          , difficulty level and even special tricks and combos. Wuthering Waves
          is also a team based game and it’s only by combining both these
          factors to the highest level a character can reach optimal
          performance. Our Wuthering Waves tier lists were created with the help
          of various players who shared their thoughts about Wuthering Waves
          characters and their performance with us in both TOA and WW modes. If
          you want to learn more about the characters and why we rated them in
          that way, check their profiles -{' '}
          <strong>
            in the Review tab we have posted short reviews and pros & cons.
          </strong>
        </p>
        <Accordion className="tier-list-accordion">
          <Accordion.Item eventKey="0">
            <Accordion.Header>About the Tier List</Accordion.Header>
            <Accordion.Body>
              <p>
                We offer separate tier lists that assess characters based on
                different criteria due to the diversity of character traits and
                abilities different contents require in order to beat them. As a
                result, we recommend you to check all of the tier lists we offer
                before concluding on a character’s full position in the game’s
                meta. The better they perform in a variety of gamemodes, the
                more valuable they are overall.
              </p>
              <p>
                <strong>
                  Important! Characters are ordered alphabetically within a
                  tier.
                </strong>
              </p>
              <h6>Tower of Adversity</h6>
              <p>
                This tier list assesses characters based on their combat
                potential in one of Wuthering Waves’ most challenging endgame
                contents, Tower of Adversity (ToA). This tier list assesses
                their performance in the <strong>Hazard Zone</strong> that
                resets periodically, as other zones do not reset. ToA is a mode
                similar to Genshin Impact’s Spiral Abyss or Honkai Star Rail’s
                Memory of Chaos and requires 3 teams of 3 equipped with strong
                Echoes and good Weapons. This mode offers a reasonable mix of
                Boss encounters (single target) and Elite packs (2-3 targets)
                along with rare AoE scenarios (5+ targets), allowing different
                categories of characters to find a niche. However, a bias
                towards single-target is present, as the most challenging floors
                are centered around beating Boss enemies.
              </p>
              <h6>Whimpering Wastes</h6>
              <p>
                This tier list assesses characters based on their combat
                potential in another challenging endgame content, Whimpering
                Wastes (WhiWa). This gamemode, in a similar fashion to Honkai
                Star Rail’s Pure Fiction, heavily favours a character’s ability
                to deal with several waves of smaller, lower health enemies
                quickly (AoE). It requires 2 teams of 3 equipped with strong
                Echoes and good Weapons. AoE characters are very strongly
                favoured over single-target ones, almost always creating a big
                discrepancy between the two archetypes.
              </p>
              <h6>Skill Ceilings</h6>
              <p>
                As an action-combat game, Wuthering Waves offers many combat
                mechanics for players to exploit - some of which are not easily
                executed, but can boost character performance drastically. Swap
                Cancelling is an example of such a mechanic, allowing you to
                switch out a character mid-attack to a new character while still
                fully executing the move you interrupted, essentially allowing
                you to attack with two characters at once. Such techniques won’t
                be for everyone, but a tier list must assess characters based on
                their optimal performance in optimal teams. With that being
                said, in order for our tier list to assess the performance of
                characters to the majority of the playerbase, we will only
                consider the easiest and most powerful swap cancels, such as
                Encore’s Cosmos Rupture, the Sentry Construct Echo skill, and
                other similarly lengthy animations. The most complex swap
                cancels with short time windows, such as Calcharo’s Death
                Messengers or Changli’s True Sights, will only be considered for
                certain character tags (Cancel, Expert) and will not affect
                characters’ positions on the tier list.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Criteria and roles</Accordion.Header>
            <Accordion.Body>
              <p>
                <strong>
                  Buffs and debuffs that vary periodically are not considered,
                  but may impact short-term unit performance.
                </strong>
              </p>
              <h6>Criteria</h6>
              <ul>
                <li>
                  All characters are assumed to be utilized in their full best
                  3-man team, played with optimal baseline rotations without
                  input mistakes, using best Echo main sets and correct Energy
                  Regen values. This means the overwhelming majority of
                  rotations will be executed in a simplistic, one character at a
                  time manner;
                </li>
                <li>
                  All characters are using maximum level gold rarity Echoes;
                </li>
                <li>
                  All Echo main stats are considered to be best in slot for that
                  character’s build;
                </li>
                <li>
                  Each Echo used by characters is assumed to have 5 sub-stats
                  with average rolls;
                </li>
                <li>
                  15 Echo sub-stats are designated as “good” sub-stats the
                  character would desire where the remaining (10) are random.
                  This represents fairly high quality endgame gear;
                </li>
                <li>
                  5★ characters are all rated at Sequence 0 unless otherwise
                  stated such as the Rover (Spectro) who starts at Sequence 6.
                  4★ characters are rated at Sequence 6.
                </li>
                <li>
                  All characters are assessed as if they have access to R1 of
                  all 4★ weapons, R5 of all 2★ and 3★ weapons and R1 of the
                  standard 5★ Winter Brume weapon series, any one of which is
                  given to select for free at Union Level 45. The best out of
                  all these choices is used for their assessment;
                </li>
                <li>
                  All characters are considered to be the maximum possible level
                  with all Fortes upgraded to maximum rank with a maximum level
                  Weapon.
                </li>
              </ul>
              <h6>Roles</h6>
              <p>
                We compare characters within their role - so DPS are compared to
                other DPS for example, and their ratings are adjusted based on
                their performance within the role. Please do not compare
                characters between roles, as their ratings are based on entirely
                separate criteria.
              </p>
              <p>
                <strong className="dps">DPS</strong>{' '}
              </p>
              <p>
                DPS characters focus on being your main damage dealers and the
                rest of your team is built to synergize with them and make them
                stronger. Stacking Echo set bonuses, character kits and Outro
                Amplify bonuses together to power up a DPS is the goal of a lot
                of meta teams. Some exceptions exist as certain DPS characters
                can be played with multiple characters of the same role, but
                they’ll generally aim to occupy the most of your team’s on-field
                time, sometimes even up to 14 or 15 seconds. This time can vary
                depending on the character, but in all cases they’ll have the
                goal of making the most of your team’s buffs to deal the
                majority of your team’s damage.
              </p>
              <p>Criteria that impact ratings for DPS:</p>
              <ul>
                <li>
                  Character rotation difficulty (potential for mistakes, or
                  severe decrease in performance if their playstyle isn’t the
                  absolute best possible);
                </li>
                <li>Character damage within best possible team;</li>
                <li>
                  Character on-field flexibility and adaptability (Does being
                  forced to dodge hurt them? Are they able to follow a scripted
                  rotation easily? Can they adapt to different enemy
                  mechanics?);
                </li>
                <li>
                  Character ability to deal with different situations (AoE,
                  Cleave, Single Target). This has a minor impact in ToA as
                  single-target is favoured. In Whimpering Wastes, dealing AoE
                  or Cleave damage is heavily favoured.
                </li>
              </ul>
              <p>
                <strong className="specialist">Hybrid</strong>
              </p>
              <p>
                Hybrid characters directly support your damage dealer of choice,
                often with incredibly synergistic kits, specialized Outro buffs
                like “Amplify” multipliers or other beneficial effects, or even
                by being a great source of secondary damage. They’re often built
                to complete their rotation right before your DPS performs their
                full damage combo, as their buffs may, in some cases, expire
                when switching. Hybrid characters, fittingly to their name, can
                often execute a variety of roles and offer offensive, supportive
                or sustaining abilities, if not all three at the same time. As a
                result of this, they fill the widest variety of niches and
                playstyles. They typically want to execute their combos in the
                shortest time windows possible in order to generate Concerto and
                Resonance Energy quickly to get their buffs up for your main
                damage dealer in the shortest amount of time. Certain Hybrid
                characters may be especially desired to deal additional damage
                in concordance with your main damage dealer, but in any case
                they’ll usually want to swap in and out of the field quickly.
              </p>
              <p>Criteria that impact ratings for Hybrid:</p>
              <ul>
                <li>
                  Character rotation difficulty (potential for mistakes, or
                  severe decrease in performance if their playstyle isn’t the
                  absolute best possible);
                </li>
                <li>Character performance within best possible team;</li>
                <li>How many effective teams the character is playable in;</li>
                <li>
                  Impact of kit on a team’s performance (units that can carry
                  teams a rank higher);
                </li>
                <li>
                  Character on-field flexibility and adaptability (Does being
                  forced to dodge hurt them? Are they able to follow a scripted
                  rotation easily? Can they adapt to different enemy
                  mechanics?);
                </li>
                <li>
                  Character effective damage contribution after including their
                  buffs on the team;
                </li>
                <li>Total on-field time required to perform rotation.</li>
              </ul>
              <p>
                <strong className="amplifier">Support</strong>
              </p>
              <p>
                Support characters must have the capability to provide benefit
                to the team regardless of the order in which they are switched
                in, so as to not conflict with the majority of Hybrid
                characters’ Outro buffs, that tend to solely buff the next
                character to enter. Their primary utility is to provide
                team-wide buffing, but they may often be desirable by providing
                defensive and sustaining/healing capabilities as well; all of
                this in the shortest field time possible, as they typically
                aren’t personally responsible for the majority of their team’s
                damage, in order to maximize the potential of your DPS and
                Hybrid characters.
              </p>
              <p>Criteria that impact ratings for Supports:</p>
              <ul>
                <li>
                  Character rotation difficulty (potential for mistakes, or
                  severe decrease in performance if their playstyle isn’t the
                  absolute best possible);
                </li>
                <li>Character performance within best possible teams;</li>
                <li>How many effective teams the character is playable in;</li>
                <li>
                  Impact of kit on a team’s performance (units that can carry
                  teams a rank higher);
                </li>
                <li>
                  Character on-field flexibility and adaptability (Does being
                  forced to dodge hurt them? Are they able to follow a scripted
                  rotation easily? Can they adapt to different enemy
                  mechanics?);
                </li>
                <li>
                  Character effective damage contribution after including their
                  buffs on the team;
                </li>
                <li>Total on-field time required to perform rotation.</li>
              </ul>
              <h6>Other criteria affecting the ratings</h6>
              <p>
                The criteria above this section apply to all our tier lists;
                however, each unique tier list rates characters based on their
                average value in the specific mode it represents. Hence, the
                higher a character is rated on a given tier list, the greater
                their power and usability in the mode, making accessing all
                rewards easier for players.{' '}
                <strong>
                  We do not consider going for the highest scores or lowest
                  time, only the ability to easily max out rewards.
                </strong>{' '}
                However, some criteria may be specific to certain character
                archetypes, or within certain modes altogether, depending on the
                tier list you view. They are as follows:
              </p>
              <ul>
                <li>
                  For certain Hybrids: ability to perform effectively at lower
                  levels of investment (if applicable, this is only a slight
                  bonus judged on a separate scale and never negatively impacts
                  other character ratings);
                </li>
                <li>
                  For Healing and Shielding Supports: how much sustain/defensive
                  utility they provide to the team, how much they allow for
                  mistakes during gameplay;
                </li>
                <li>
                  For Whimpering Wastes characters: ability to gather enemies
                  efficiently, ability to deal with multiple waves of enemies
                  quickly.
                </li>
              </ul>
              <p>
                Additional specific criteria may be developed according to game
                updates/meta shifts.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Tags</Accordion.Header>
            <Accordion.Body>
              <h6>Special tags</h6>
              <p>
                Tags represent the most defining features of a character's kit.
              </p>
              <ul>
                <li>
                  <strong className="archetype">ST</strong> - Character deals
                  mostly single-target damage to enemies but can hit multiple
                  enemies if they are stacked directly on top of each other.
                </li>
                <li>
                  <strong className="archetype">Cleave</strong> - Character has
                  a mix of smaller range cleaving attacks or attacks that strike
                  in a radius around them but does not consistently hit all
                  enemies on screen with the majority of their rotation.
                </li>
                <li>
                  <strong className="archetype">AoE</strong> - A large portion
                  of these characters’ damage or benefit is in the form of pure
                  AoE, hitting most if not all enemies on the battlefield.
                </li>
                <li>
                  <strong className="archetype">Debuff</strong> - Character can
                  provide or revolves around an elemental debuff that stacks on
                  enemies (called Status Infliction in game), such as Spectro
                  Frazzle, Havoc Bane, Aero Erosion and similar.
                </li>
                <li>
                  <strong className="pro">Heal</strong> - Character can heal
                  themselves or the team, providing sustain and the ability to
                  trigger the 5-piece Rejuvenating Glow set bonus without extra
                  help.
                </li>
                <li>
                  <strong className="pro">Shield</strong> - Character can apply
                  shields to themselves or the whole team and/or provide
                  defensive buffs and benefits, further increasing
                  survivability.
                </li>
                <li>
                  <strong className="pro">Coord</strong> - Character can perform
                  or benefit from Coordinated Attacks consistently, thanks to a
                  major portion of their kit revolving around Coordinated
                  Attacks or being able to execute Coordinated Attacks,
                  providing damage and/or utility even when off-field.
                </li>
                <li>
                  <strong className="pro">Multirole</strong> - When built or
                  used in an alternative way, can move to a different category
                  on the tier list, enabling their use in a different role even
                  if unconventional. Characters only obtain this tag if the
                  alternative build/playstyle is close in viability to their
                  main build/playstyle.
                </li>
                <li>
                  <strong className="cons">Cancel</strong> - Character needs or
                  is widely considered to be far more powerful with the use of
                  “swap cancelling” to enhance their performance.
                </li>
                <li>
                  <strong className="cons">Ult</strong> - Character is
                  ultimate-centric with most of their damage occurring during or
                  via an ultimate, creating a reliance on it. Without the
                  ultimate available, the character feels lackluster.
                </li>
                <li>
                  <strong className="special">Expert</strong> - Character can be
                  considered one placement higher than their default tier list
                  ranking, but has an optimal playstyle that needs to be
                  mastered to qualify for that higher rating. Characters only
                  obtain this tag if the expert playstyle creates a significant
                  discrepancy in power compared to their baseline playstyle,
                  more so than characters without the tag.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>Ratings & Meta Lines</Accordion.Header>
            <Accordion.Body>
              <h6>Meta Lines</h6>
              <p>
                Meta Lines categorize each section of the tier list into a power
                bracket. Here are the three brackets:
              </p>
              <ul>
                <li>
                  <strong>Apex Characters (T0 & T0.5)</strong> - characters in
                  this bracket are the cream of the crop. They’ll make clearing
                  content a breeze, by doing some of the best damage numbers in
                  the game, and/or providing massive buffs or debuffs quickly.
                  Certain sustain characters even make dying practically
                  impossible no matter how little you dodge enemy attacks. In
                  their best teams, these characters make achieving the best
                  results simple and consistent.
                </li>
                <li>
                  <strong>Meta Characters (T1 & T1.5)</strong> - characters in
                  this bracket still clear endgame contents easily, however they
                  may need you to put more effort in your playstyle, or require
                  higher levels of investment to perform comparatively to top
                  tiers. This can be because they’re more restrictive in their
                  builds, they’re mechanically intensive, they’re overshadowed
                  by better options, or they possess a notable weakness or
                  downside. Most often a mix of these reasons, they’ll
                  regardless not disappoint if you give them the conditions they
                  need to shine.
                </li>
                <li>
                  <strong>Niche Characters (T2 & T3)</strong> - characters in
                  this bracket are lacking in their respective roles. They have
                  one fundamental flaw that makes them comparatively worse to
                  higher tier characters, or they have multiple smaller flaws
                  that add up together. More often than not, they cover a niche
                  that’s filled by another unit that performs much easier than
                  them. These characters still possess the ability to clear all
                  content given the right amount of love, however they’re played
                  noticeably less and will require higher maintenance to
                  perform.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              Changelog
              <span className="tag new">Updated!</span>
            </Accordion.Header>
            <Accordion.Body>
              <h6>28/03/2025</h6>
              <p>
                Now that Kuro Games lowered the HP pools of Whimpering Wastes
                back to its intended difficulty, we were able to properly test
                the whole cast in Whimpering Wastes to come up with some new
                tier list changes! We'll list them below. We've also finally
                gotten around to drawing some conclusions on Aero Rover —
                they'll be present on both tier lists so let's get the two ToA
                changes out of the way first:
              </p>
              <ul>
                <li>
                  <strong>Rover(Aero)</strong> added as T3 Support.
                </li>
                <ul>
                  <li>
                    Aero Rover is a character who has some problems they can't
                    really work around. First of all, they have a dedicated
                    F2P-accessible 5-star weapon with an amazing effect for
                    them, and it drastically enhances their kit because it
                    provides the whole team with Aero DMG Amplify. Here's the
                    thing: it's locked at 10% Amplify at R1 right now, and even
                    when they get access to R5, it won't be that impressive
                    compared to a Shorekeeper or a Verina in terms of buffing
                    potential, besides it's exclusive to Aero teams so aside
                    from this niche Jiyan option they pretty much don't fit
                    anywhere.
                  </li>
                  <li>
                    Some rapid-fire issues include a lack of teams, a lack of
                    buffing potential, a rotation much longer than the top
                    supports, a lack of damage compared to other choices,
                    fulfilling a role in a team (Support) that's already too
                    contested by meta options, a lack of access to their full
                    kit (locked at S1R1), taking away the possibility to use the
                    crucial Spectro Rover and Havoc Rover, and a lack of being
                    able to exploit their full kit (no one makes use of Aero
                    Erosion right now, and the only debuff applier we have
                    outside of Rover is Confession Phoebe who's infinitely
                    better as a DPS).
                  </li>
                  <li>
                    This isn't even mentioning their inconsistency problems —
                    against smaller enemies they won't be able to hit their
                    mid-air attacks all the time, which are crucial to their
                    rotations and can mess you up completely if you don't hit
                    them, and no matter what changes happen to their synergies
                    and damage/buffing potential this won't be helped. All in
                    all they land as a bottom tier character, but hopefully they
                    find some use in the future when someone allows them to
                    exploit their kit.
                  </li>
                </ul>
                <li>
                  <strong>Taoqi</strong> T3{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T2.
                </li>
                <ul>
                  <li>
                    On her end of things, Taoqi has seen a rise in usability
                    ever since Carlotta came out, as a budget buffer for her.
                    She's faster than the other budget Resonance Skill Amplify
                    option Lumi (unless you double Intro into Lumi with Verina
                    instead of Shorekeeper, but this doesn't help in the best
                    possible teams) and while she buffs less than Lumi, her
                    cooldowns typically line up perfectly with Carlotta's if you
                    optimize your Yangyang, because Yangyang tends to be too
                    fast for Carlotta to keep up, even if the two work
                    brilliantly for more casual players. All in all Taoqi
                    provides buffing for Carlotta with cooldowns that line up
                    just right and this factor allows her to find a legitimate
                    use case if you lack premium options for the Montelli lady,
                    justifying a rise to T2.
                  </li>
                </ul>
              </ul>
              <p>Now let's talk about WhiWa changes:</p>
              <ul>
                <li>
                  <strong>Cantarella</strong> added as T1.5 Hybrid. To
                  understand Cantarella's placement, we need to look at what she
                  does. She buffs Havoc and Skill damage. In terms of Skill
                  damage, she's not that good with Changli, Jinhsi isn't that
                  strong, and Carlotta has much better teammates in the form of
                  Brant and Zhezhi. Finally, in terms of Havoc damage, no
                  character truly stands out aside from Camellya — unfortunately
                  Roccia, Sanhua and even Danjin all outclass Cantarella in
                  terms of buffing strength. This makes Cantarella difficult to
                  stand out, so we're placing her in T1.5 for now.
                </li>
                <li>
                  <strong>Rover (Aero)</strong> added as T3 Support. Refer to
                  our ToA explanation for most of the reasoning, however do be
                  aware Aero Rover is worse in WhiWa than ToA because small
                  enemies are the name of the game and they won't be able to hit
                  them with their mid-air attacks a lot of the time, forcing you
                  to alter your rotation and making things less smooth. This
                  justifies their positioning in T3 further.
                </li>
                <li>
                  <strong>Baizhi</strong> placed on Watchlist. This time around
                  Baizhi isn't placed on the Watchlist to go down, but instead
                  to go up — it all depends on one factor: the Plunderer's
                  Captain Seal token, which does huge Electro nukes after
                  multiple instances of healing. It makes clearing the content
                  much easier when your team can't exploit more specialized
                  buffs. So far only gold tokens have changed in the mode, so if
                  it turns out that purple tokens always stay the same, she'll
                  go up a tier as she exploits this powerful token very well.
                  However, if purple tokens do change next WhiWa iteration, then
                  Baizhi will be staying in T1.5.
                </li>
                <li>
                  <strong>Brant</strong> T1{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0.5. Something we had initially missed on Brant is that his
                  Forte Anchor nuke has a lingering hitbox that stays for a long
                  time — that means that when you activate it, you'll typically
                  clear at least 2 waves at once using a single skill (can be
                  even more with good luck and good ping). Generally though, as
                  he's able to buff Changli, a top tier unit, and nuke multiple
                  waves at once, this makes him a much more powerful WhiWa
                  character than we expected, so we're raising him up.
                </li>
                <li>
                  <strong>Carlotta</strong> T1{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0.5. Initially we placed her in T1.5, turns out Carlotta's a
                  powerhouse in WhiWa, no less. She plays pretty much no
                  different than in Tower of Adversity, which makes her super
                  simple to use. Her damage is off the charts, so her big skills
                  can clear waves multiple times. To top it all off, she has a
                  great F2P weapon option in the form of Static Mist. This makes
                  her one of the best DPS units in the mode, comfortably T0.5
                  next to Xiangli Yao.
                </li>
                <li>
                  <strong>Changli</strong> T0.5{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0 & Expert tag removed. Now that Brant's released, Changli is
                  more powerful than ever in WhiWa; he makes her easy to play
                  and he's able to buff her damage so she can clear multiple
                  waves on her own without the aid of a second DPS. She retains
                  that team flexibility, Emerald of Genesis is amazing on her,
                  and to sum it up she has everything needed to be a top tier
                  contender for WhiWa, which makes her T0 (we removed the Expert
                  tag on her because, well, she can't really go higher than T0).
                </li>
                <li>
                  <strong>Encore</strong> - Expert tag added (+1). Encore
                  surprisingly has a lot of potential to be tapped into for the
                  more experienced part of the playerbase. We initially thought
                  she could be raised up a tier fully, but at a baseline she
                  remains difficult to use in WhiWa; this is because she has
                  some attacks with some AoE but they're very low-range which
                  requires very precise execution, timing and positioning, so it
                  warrants an Expert tag.
                </li>
                <li>
                  <strong>Youhu</strong> T2{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T3. Unfortunately for Youhu, buffing Coordinated Attacks isn't
                  a desirable niche in WhiWa. All Coordinated Attackers
                  available are either low tier, or the high tier Coord units do
                  so through the application of a mark, which doesn't last
                  multiple waves so it doesn't contribute to close to any
                  damage. This makes Youhu have very little justification in the
                  game mode especially when compared to other healers, so she'll
                  have to wait until a proper Coord unit makes her a viable
                  buffer before she sees any raises.
                </li>
              </ul>
              <h6>27/03/2025</h6>
              <p>
                This time around the Tier List Changelog won't be as long as the
                previous ones. Three reasons: first, the new characters we got
                this 2.2 update didn't change too much to the meta, so little
                changes were justified in relation to them. Second, when it
                comes to ToA, we didn't have many of the points we made
                discussed by the community, so we judged major changes
                unnecessary (let us know if you have any feedback though).
              </p>
              <p>
                Third, Whimpering Wastes had overinflated HP pools which Kuro
                changed this patch to make things easier; this made testing for
                the current meta difficult as we wanted to push out a changelog
                on 2.2's release in time for Cantarella and Aero Rover.
                Unfortunately we haven't had enough time to try out Aero Rover
                either but we have almost everything ready — expect them to be
                added in a day or two.{' '}
              </p>
              <p>
                With that out of the way, let's get into our changelog for ToA.
                As a reminder, Whimpering Wastes will be updated in a few days
                and should receive more changes than what we have for ToA here —
                more changes are warranted for WhiWa, we just couldn't test
                anything yet.
              </p>
              <ul>
                <li>
                  <strong>Cantarella</strong> added as T1. Cantarella is a good
                  character that can fulfill many roles, such as dealing damage,
                  healing and buffing — very similar to units like Brant in that
                  sense, and similar in strengths and weaknesses to Yinlin,
                  which is why we're placing her in T1.
                </li>
                <ul>
                  <li>
                    She has a solid position in the meta, but runs into issues
                    in regards to her Resonance Energy, and her lack of a
                    definitive top meta team where she's the best option. The
                    only unit able to make a full use of Cantarella's Skill and
                    Havoc DMG Amplify Outro skill is Danjin, and while she can
                    act as a Main DPS (this actually makes the team pretty
                    viable), it's hard to justify playing Danjin this way
                    instead of as a buffer for units like Havoc Rover (or even
                    Camellya if you don't have Sanhua available).
                  </li>
                  <li>
                    This means in top Skill damage teams, she's outdone by units
                    like Zhezhi, and in top Havoc damage teams, she's outdone by
                    units like Sanhua who coincidentally both stand in T0. This
                    makes it difficult to justify rating her any higher than T1.
                    With that being said, Cantarella can hope for a future
                    Havoc-Skill DPS stronger than Danjin who will be able to
                    allow her to reveal her full potential, but until that
                    happens, it's unlikely she goes up the tier list as things
                    stand.
                  </li>
                </ul>
                <li>
                  <strong>Encore</strong> - Expert tag added (+1). Encore is a
                  character that required a lot of testing from us because she's
                  one of the game's most underused DPS units, so there's not
                  that much to go off of. We fortunately have come up with some
                  optimizations for her that allow you to squeeze much more
                  damage out of her when executing proper animation cancels
                  during her Ultimate window (similarly to Jiyan — the two
                  always end up being comparable somehow). All in all, this
                  warrants the addition of an Expert +1 tag thanks to the extra
                  damage you can squeeze out of her — new rotations will be up
                  on the website with calculations very shortly, hopefully in
                  time for our WhiWa tier list update, so stay tuned!
                </li>
                <li>
                  <strong>Yuanwu</strong> - Shield tag added. Pertaining to
                  Yuanwu's S4.
                </li>
                <li>
                  <strong>Rover (Havoc)</strong> - Heal tag added. Pertaining to
                  Havoc Rover's S3.
                </li>
              </ul>
              <h6>06/03/2025</h6>
              <p>
                Our first Whimpering Wastes tier list update is here, Brant just
                released, and a ton of revisions to our past characters have
                been made — there's a lot of material to go over so we didn't
                finish everything for the entire character cast, however we're
                happy to announce we extensively revised our tier list
                assessments for every character on both tier lists, and we
                revised our rotations for around half of the character cast as
                well (not everything is up on the website just yet, we've added
                a few like Youhu/Changli/Baizhi but the rest will be up soon).
              </p>
              <p>
                This was done because we revamped the Expert tag and some
                character rankings along with it: how we'll go about it from now
                on is feature every character placement at a strictly casual
                level following the definition in our Tier List criteria
                section. However the Expert tag is now allowed to cover
                discrepancies of more than one tier. In the future this can be
                as many tiers as needed, but as things stand certain characters
                have gotten the "Expert +1" tag while others have gotten the
                "Expert +2" tag. This means for example that a character in T1
                with the Expert +1 tag will be T0.5 at an Expert level,
                meanwhile a character in T1 with the Expert +2 tag will be T0.
                We'll list everyone who acquired the Expert +2 tag below —
                everyone else is staying at Expert +1.{' '}
              </p>
              <p>
                Additionally, for visualization purposes, we're clarifying on
                everyone's character icon that they're graded at S0R0 - this
                means no sequence nodes and no limited 5-star weapons (this
                doesn't change anything to our rankings, just makes things
                easier to visualize for everyone).
              </p>
              <p>
                We're separating our changelog in 2 categories so let's tackle
                our ToA tier list first:
              </p>
              <ul>
                <li>
                  <strong>Brant</strong> added as T1. Brant's very strong - he
                  welcomes quickswap with other mid-air characters even though
                  it's not necessary, his playstyle is straightforward, he deals
                  a ton of damage, he heals and shields your party, he has a
                  great Outro buff; so to summarize, all good things. However, a
                  ton of his potential is locked behind his signature weapon,
                  even more so than Yinlin. It offers him big amounts of Energy
                  Regen that he needs, and a lot of extra damage on top. This
                  makes it the only weapon that really allows him to utilize his
                  signature Echo set, Tidebreaking Courage, while still being
                  worth it for his total damage. With his weapon included he'd
                  likely land around T0.5; however too much of his damage is
                  locked behind it so we're leaving him comfortably in T1. Note
                  that this means he's still a very strong unit, just not a top
                  tier one.
                </li>
                <li>
                  <strong>Calcharo</strong> - Expert tag changed (+2). It should
                  be no surprise to many that Calcharo has the biggest meta
                  discrepancy between a casual player and an Expert player who's
                  willing to learn all of his optimizations. The new Expert tag
                  revamp was done mostly because of him — it's impossible to
                  depict his meta position accurately without it. Good luck to
                  any Calcharo mains who are willing to learn all his
                  optimizations; it's really difficult but quite rewarding.
                </li>
                <li>
                  <strong>Changli</strong> T0.5{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1 & Expert tag changed (+2). If you took a closer look at our
                  past Tier List changelogs, you'll realize this change has been
                  on our minds for a little while. The new Expert tag revamp is
                  perfect to more accurately represent Changli's meta
                  performance for casuals. Brant helps her cause a ton as a
                  hypercarry unit, so she's very comfortably in T1, however you
                  can swap cancel so many of her abilities she can work with
                  almost all other DPS units in the game in quickswap
                  playstyles. Her versatility and viability at an Expert level
                  is so strong it no doubt warrants her the status of top tier,
                  however players who aren't willing to swap her every ability
                  might experience some issues with her. She's still strong in
                  that case of course, just not top tier, so we deem this
                  placement most accurate for her.
                </li>
                <li>
                  <strong>Danjin</strong> - Heal tag added. Simply fixing a
                  small oversight (Danjin heals herself on her Forte heavy
                  attack).
                </li>
                <li>
                  <strong>Jiyan</strong> - Expert tag added (+1). In our past
                  tier lists we considered Jiyan getting an Expert tag due to
                  his Double Dragon rotation providing a significant boost to
                  his damage; however it didn't warrant T0 performance. With
                  more strong DPS units releasing causing his drop to T1, he can
                  now comfortably wear the Expert tag, because he's still super
                  strong himself if you're willing to properly execute optimized
                  rotations.
                </li>
                <li>
                  <strong>Mortefi</strong> T1{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0.5. Mortefi's not only strong with Jiyan anymore; Phoebe is
                  one of the new candidates for the top 3 DPS units in the game
                  and he works brilliantly with her, on par with Sanhua. This
                  newfound flexibility in 2 top teams with both Jiyan and Phoebe
                  warrants his ascension back to T0.5.
                </li>
                <li>
                  <strong>Xiangli Yao</strong> - Cancel tag added. Xiangli Yao
                  may warrant an Expert tag in the future because he's very
                  swap-cancel friendly in Dual DPS team comps and can output
                  ludicrous amounts of damage in the process; however unless you
                  play him like a speedrunner (which isn't what our Expert tag
                  covers), he doesn't warrant a T0 placement so we won't add the
                  Expert tag to him. With that being said, if he were to ever
                  drop to T1 with more powerful DPS units coming out in the
                  future, it's very likely he'll get the Expert tag on top of
                  the Cancel tag, so expect that to happen eventually.
                </li>
              </ul>
              <p>And here are the WhiWa tier list changes:</p>
              <ul>
                <li>
                  <strong>Brant</strong> added as T1. In Whimpering Wastes,
                  Brant is especially strong because he buffs Changli, who's
                  without a doubt a top tier unit, by a lot. He has a good
                  amount of AoE in a lot of his abilities and his Forte skill
                  hits like a truck. However almost all of his damage is
                  concentrated within that single Forte ability, which causes
                  issues to clear multiple smaller waves of enemies. As a result
                  Changli prefers Dual DPS playstyles with other characters and
                  he lands himself in T1 for now: strong but not top tier.
                </li>
                <li>
                  <strong>Calcharo</strong> - Expert tag changed (+2). WhiWa
                  being exclusively AoE-focused really doesn't help Calcharo who
                  has a ton of issues landing his Death Messengers on more than
                  one enemy - it's possible, just VERY difficult. He warrants
                  Expert +2 by being able to perform via proper positioning,
                  however for casual players he'll always land in the bottom
                  tier - we advise not to use him in the mode if you aren't
                  willing to optimize a lot (which is very difficult in
                  Calcharo's case).
                </li>
                <li>
                  <strong>Carlotta</strong> T1.5{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T1. Carlotta performed way better than expected when the team
                  first tried her out — turns out her abilities have just enough
                  AoE that with minor amounts of positioning (which really isn't
                  too difficult on her), she'll perform completely fine even as
                  the sole DPS of the team. This comfortably justifies moving
                  her up a tier.
                </li>
                <li>
                  <strong>Danjin</strong> - Heal tag added. Simply fixing a
                  small oversight (Danjin heals herself on her Forte heavy
                  attack).
                </li>
                <li>
                  <strong>Jinhsi</strong> T1{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1.5 & Expert tag changed (+2). To say the least, Jinhsi is
                  difficult to make proper use of in WhiWa. It requires
                  completely changing your usual playstyle to instead rotate
                  fast with her on low stacks, typically in Dual DPS team comps.
                  She practically necessitates that to perform so we're dropping
                  her to T1.5 and adding Expert +2 to her — because truth is,
                  when you optimize Jinhsi in this gamemode, she's way up there
                  as one of the highest performing units in the game (especially
                  with Changli as her partner, as the team has some of the
                  highest damage potential out there).
                </li>
                <li>
                  <strong>Mortefi</strong> T1{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0.5. Mortefi, similarly to Carlotta, has also been performing
                  better than expected — he has no AoE abilities, however his
                  buffs to Jiyan, a top tier unit without question, are very
                  appreciated and outside of Dual DPS team comps Jiyan ends up
                  performing the best with Mortefi. This makes Mortefi warrant
                  an Apex character placement.
                </li>
                <li>
                  <strong>Phoebe</strong> T1{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1.5 & Expert tag changed (+2). Phoebe is very similar to
                  Jinhsi. In WhiWa she particularly enjoys Dual DPS playstyles
                  and can perform amazingly well if you swap cancel a lot
                  between her, a second DPS, and Spectro Rover (which is why
                  both have the Cancel tag). However that playstyle is locked
                  behind Expert play and without that she has a lot of issues in
                  multiwave, due to needing Spectro Frazzle on enemies to do
                  damage. As a result she drops a tier but keeps her initial
                  Expert placement so she gets a +2.
                </li>
                <li>
                  <strong>Verina</strong> T0.5{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0. For casual players who won't be making use of
                  Shorekeeper's optimal rotations, Verina takes significantly
                  less field time than Shorekeeper does. We initially placed
                  Verina below her because Shorekeeper has grouping, an AoE nuke
                  on Intro and stronger buffing. However for our core casual
                  audience Verina may often end up outperforming Shorekeeper, so
                  she warrants a T0 placement without a doubt.
                </li>
                <li>
                  <strong>Xiangli Yao</strong> - Cancel tag added. Refer to our
                  ToA changelog — exact same reasoning here, however Xiangli Yao
                  has good AoE so he's likely to drop in a more distant future
                  than in ToA (no guarantees though).
                </li>
                <li>
                  <strong>Yinlin</strong> - Placed on Watchlist. Yinlin is
                  somewhat of a question mark; after revising her rotations we
                  realized the extra Concerto energy she gains from multiple
                  enemies possessing a mark at the same time isn't all too
                  powerful, especially since her Coordinated Attack mark is not
                  possible to upkeep across several waves of enemies. She's
                  still strong especially with Xiangli Yao however — so we ask
                  for your feedback whether she should be in T0.5 or T1
                  (reminder: our Discord server is the best place to communicate
                  your thoughts to the team).
                </li>
                <li>
                  <strong>Zhezhi</strong> T1{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1.5. Zhezhi simply doesn't perform as well as we thought in
                  WhiWa - she lacks big AoE abilities, her damage isn't
                  sufficient to clear waves quickly, she doesn't buff top tier
                  units. She's good with Carlotta and Jinhsi, sure, but even
                  when optimized she's not at the level of Dual DPS playstyles
                  with both. Carlotta saves her placement a little by still
                  being comparable to Dual DPS comps somewhat (which isn't the
                  case for Jinhsi at all), however she's dropping to T1.5 as a
                  result.
                </li>
              </ul>
              <h6>21/02/2025</h6>
              <p>
                With the introduction of the new Whimpering Wastes endgame mode,
                a ton of changes were necessary to the tier list. We made a
                brand new one, separate from the Tower of Adversity tier list -
                we recommend you check both tier lists out to assess a
                character's full value!
              </p>
              <p>
                We took the opportunity to fully revise our entire criteria and
                information about the tier lists from scratch. If you want the
                full details of how we evaluate characters for each tier list,
                you're welcome to read those sections for precisions as well. As
                the Whimpering Wastes tier list is brand new, a changelog
                detailing every character's ranking won't be provided — refer to
                our criteria to understand everyone's rankings on that list, the
                team tried their absolute best to make them as comprehensive as
                possible.
              </p>
              <p>
                By the way, we added meta lines, you might be familiar with them
                from a lot of our other tier lists — they don't change anything
                to character rankings or tier placements, but they do clarify
                the meanings of the tiers. As usual, precisions can be found in
                our information sections above this changelog. With that said,
                let's get into what's changed for the Tower of Adversity tier
                list, we have lots of changes there. Special thanks to{' '}
                <a
                  href="https://www.youtube.com/@saintontas"
                  target="_blank"
                  rel="noreferrer"
                >
                  Saintontas
                </a>{' '}
                ,{' '}
                <a
                  href="https://www.youtube.com/@e-thin"
                  target="_blank"
                  rel="noreferrer"
                >
                  E-thin
                </a>{' '}
                and our Discord members for the feedback!
              </p>
              <p>List of changes (ToA):</p>
              <ul>
                <li>
                  <strong>Baizhi</strong> T1{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1.5. We judged T1 a bit too high for Baizhi on the Support
                  scale, as a lot of teams can choose not to run a generalist
                  healer if Verina or Shorekeeper aren't options. She still has
                  great supportive capabilities, which is why she stays in the
                  Meta Character lines. However, she's also not necessary to
                  Phoebe owners, since Spectro Rover is a perfectly fine support
                  in Phoebe teams. For these reasons, we bumped her down half a
                  tier.
                </li>
                <li>
                  <strong>Camellya</strong> T0{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T0.5. Since we added her to the Watchlist last update, the
                  team looked into Camellya a ton. It's safe to say she's the
                  character we looked into the most. We even have new rotations
                  in prep for her (they'll be up soon). Lots of testing and
                  mathing led us to conclude she performs similarly in ToA to S6
                  Havoc Rover - a bit above them, but way closer than to
                  Carlotta or Phoebe for example. We brought up the issue that
                  she needs to hit enemies with all of her attacks to consume
                  her Forte bar - this IS an issue, but we found it's less
                  problematic in her white hair mode. Even still, the more the
                  enemy moves, the worse off Camellya is to deal her damage,
                  more so than other characters since she has wind-up time
                  before accessing her big spinning hits. Remember we factor in
                  the ability to cover all kinds of enemies — Camellya has less
                  of that than her T0 peers, even setting aside pure damage
                  performance. So we're bumping her down to T0.5. Havoc Rover
                  and Xiangli Yao stay there too, due to being comparable in
                  performance (we'd argue Camellya's stronger than them, just
                  not enough to separate their tier placements).
                </li>
                <li>
                  <strong>Encore</strong> T0.5{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1. Very simple to explain why Encore is going down — none of
                  that is her fault, and she isn't performing any worse than she
                  was last tier list update. However, T0.5 is now occupied by
                  Havoc Rover, Xiangli Yao and Camellya. Those three have a
                  significant gap in power compared to Encore, so she's going
                  down to T1 because she doesn't fit alongside them.
                </li>
                <li>
                  <strong>Jiyan</strong> T0.5{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1. Jiyan can be justified the exact same way as Encore -
                  remember their pure single-target damage is similar. We'll
                  note however that Jiyan is the stronger of the two because he
                  handles multi-target situations significantly better than
                  Encore does, so he has the advantage there. ToA is too
                  single-target biased to justify differentiating the two
                  though.
                </li>
                <li>
                  <strong>Yinlin</strong> T0.5{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1. There's an argument to be made for Yinlin to stay in T0.5
                  alongside Changli. However let's remember our criteria: we
                  grade with specific weapons in mind (in the case of Yinlin,
                  that would be Cosmic Ripples). A lot of Yinlin's value resides
                  in her own damage, and Stringmaster is not only one of the
                  best weapons in the game period, it's so good for Yinlin she
                  has a notable reliance on it to do damage. She benefits from
                  it more than she seems to on our calculations if you swap
                  cancel some of her big hits as well, due to Stringmaster's
                  off-field passive boost. So seeing as without her weapon, her
                  performance is to the level of our T1 Hybrids, we're placing
                  her there.
                </li>
                <li>
                  <strong>Youhu</strong> T1.5{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T2. Youhu is honestly the character that fits the description
                  of a "niche character" the best of anyone on our tier list.
                  She works in niche teams, under niche restrictions. At best
                  she's just about the same as Baizhi, at worst she's less
                  flexible. Hence, we're placing her in T2. She may go up if a
                  future main DPS performs lots of Coordinated Attacks, but this
                  is unlikely. Until that happens, it's likely she stays in this
                  tier.
                </li>
                <li>
                  <strong>Taoqi</strong> Support —&gt; Hybrid - Hybrid fits the
                  bill way better for what Taoqi does in the current meta — we
                  aren't ignoring her defensive utility as a Support which is
                  why she has the Multirole tag, but seeing how she's way more
                  effectively used to buff someone like Carlotta or Jinhsi, we
                  chose to move her.{' '}
                </li>
                <li>
                  <strong>Aalto</strong> Expert tag removed - Removed the tag as
                  DPS Aalto simply doesn't perform to T2 level at Expert play,
                  so he doesn't fit the bill for the tag — he still works okay
                  as a DPS with his S3 though, it's enough to clear content (and
                  at least gives him a use outside of Jiyan teams).{' '}
                </li>
                <li>
                  <strong>Jianxin</strong> Heal tag added - Simply fixing a
                  small oversight on our end. We'll take the opportunity to
                  mention we've been looking into her rotations though, so
                  expect updates to her character page shortly!{' '}
                </li>
                <li>
                  <strong>Phoebe & Rover (Spectro)</strong> Debuff tag added.
                </li>
              </ul>
              <h6>13/02/2025</h6>
              <p>List of changes:</p>
              <ul>
                <li>
                  <strong>Phoebe</strong> added to the tier list (T0 DPS). We'll
                  put it as bluntly as we can: Phoebe is the best F2P DPS in the
                  game. With a weapon and teammates that don't even require you
                  to do a single pull, she performs less than half a tier below
                  Jinhsi with her premium weapon and supports. And then she can
                  get improved with better weapons and quickswap.
                </li>
                <li>
                  <strong>Rover (Spectro)</strong> T3{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T1.5. Spectro Rover is the first and only character we've ever
                  had that got their kit buffed by Kuro themselves — they can
                  now apply Spectro Frazzle, and a ton of it! This change alone
                  wasn't much in 2.0; but that now makes them mandatory to
                  Phoebe teams. Phoebe is one of the best DPS characters we ever
                  had — judging how Mortefi who is also restricted
                  teambuilding-wise is T1, we deem T1.5 fair for Spectro Rover
                  (Mortefi being unlikely to drop due to his use in Phoebe,
                  Jiyan and other teams). We also added the Multirole tag as
                  they can sport both the Rejuvenating Glow and Moonlit Clouds
                  sets very efficiently.
                </li>
                <li>
                  <strong>Rover (Havoc)</strong> T1{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0.5. Havoc Rover finally got access to their S6 for free in
                  2.1 with the main story, and with it comes a free 25% CRIT
                  Rate boost — just for comparison purposes, that's more than a
                  high base ATK 5-star sword, and that's more than a max level 4
                  cost main stat Echo. Needless to say the buff is huge. It's in
                  fact enough to propel them up to T0.5. That being said, they
                  are put on Watchlist to test how they compare with Camellya,
                  in the instance she is placed in T0.5 as well.
                </li>
                <li>
                  <strong>Lingyang</strong> T2{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T3. We wanted to drop him sooner, but tried to test Lingyang
                  more extensively in 2.0 due to the addition of the Frosty
                  Resolve set — we tried to optimize his rotations too. Sure
                  enough, it helped a tiny bit. Did it help enough to make him
                  keep up with Calcharo and Chixia though? Unfortunately not.
                  Hence he's dropping to T3. New baseline and optimized
                  rotations coming soon though!
                </li>
              </ul>
              <p>
                We had a lot of changes planned for our 2.1 Tier List update.
                Some changes went through to go along with Phoebe's release.
                When it comes to her and her teammates, we are fairly certain of
                our placements, so her pull value should remain unchanged from
                what is seen in this new edition of our Tier List. However, we
                face a new problem: two thirds of our DPS category is in our 2
                top tiers, everyone else is in the 2 bottom tiers. We noticed a
                clear discrepancy in power — and some characters are inferior to
                others within their current tiers, which we'll explain below.
                This made us want to consider cascading the tier list down: as a
                result, we added a Watchlist icon. Everyone who's on our current
                watchlist is suspected to drop by one tier. These changes may go
                through for some characters, or it won't for others. We ask for
                your feedback and will do extensive testing to decide how we
                proceed about the Tier List, all updates going through with
                Brant's release on the second half of Patch 2.1.
              </p>
              <p>
                We appreciate anyone who sends feedback our way, make sure to
                join our Discord to do so!
              </p>
              <ul>
                <li>
                  Baizhi - placed on Watchlist to cascade in relation to Spectro
                  Rover, who can also fill the role of support and is especially
                  stronger in Phoebe teams but is currently placed a tier lower.
                </li>
                <li>
                  Camellya - Camellya is our main point of discussion on the
                  whole Watchlist. She determines a ton of stuff — we consider
                  placing her alone in T0.5, above anyone but Jinhsi, Carlotta
                  and Phoebe. The reasoning? Inconsistency — Camellya needs to
                  hit her attacks for her rotations to be smooth. Against a
                  moving target, almost any enemy in the game, this isn't
                  happening. And the second this doesn't happen, her rotations
                  become longer and awkward. We factor in difficulty when
                  considering our Tier List placements, but Camellya
                  fundamentally isn't difficult to play. The issue is simply
                  that she doesn't reliably hit all her attacks against all
                  enemies. So missing with Camellya is a HUGE issue which isn't
                  exclusive to casuals — sure, on paper she sheets about the
                  same as Jinhsi. In practice though? It's easy to observe
                  there's little comparison to be made — in fact, after
                  extensive testing and mathing, we found it joins Havoc Rover
                  S6 more so than Jinhsi. So we wonder if we should drop her by
                  herself in T0.5 for that, every other DPS going down half a
                  tier as a consequence.{' '}
                </li>
                <li>
                  Changli - We've been considering dropping Changli for a long
                  while now — we're unsure of what to do with her, since for
                  casual players who aren't quickswapping constantly she would
                  land around T1. That being said, for expert players, she's
                  undeniably T0 and our expert tag doesn't cover a discrepancy
                  of two tiers. So she's placed on Watchlist as we're
                  considering simply dropping her to T1 to cater to our core
                  casual audience and specify in her Review and Pros & Cons page
                  that she's a T0 at Expert level.{' '}
                </li>
                <li>
                  Encore - Placed on Watchlist to cascade with Camellya & Jiyan
                  — Jiyan and Encore go in the same tier as their singletarget
                  performance is identical. Jiyan only has the edge in AoE but
                  this barely matters for Tower of Adversity as it's mostly
                  boss-focused. If Jiyan drops because of Camellya, Encore drops
                  too.{' '}
                </li>
                <li>
                  Jiyan - Placed on Watchlist to cascade with Camellya - his
                  damage performance simply doesn't match hers (to be expected
                  as Jiyan is a 1.0 character). So if Camellya drops, we think
                  he should drop a tier too.{' '}
                </li>
                <li>
                  Xiangli Yao - Placed on Watchlist to cascade with Camellya —
                  at a casual level he's undoubtedly worse, but at an expert
                  level with someone like Changli he catches up. So we're
                  considering dropping him but giving him the Expert tag.{' '}
                </li>
                <li>
                  Yinlin - Placed on Watchlist to cascade with Xiangli Yao —
                  Xiangli Yao is the best character that wants Yinlin as his
                  best Hypercarry Hybrid, as she's outclassed by Zhezhi in
                  Jinhsi teams. For this reason, if Xiangli Yao drops a tier, we
                  think she should drop a tier too.{' '}
                </li>
                <li>
                  Youhu - Placed on Watchlist to cascade in relation to Baizhi,
                  who's significantly more flexible than Youhu while retaining
                  very similar strength in optimal Youhu teams.
                </li>
              </ul>
              <h6>23/01/2025</h6>
              <p>List of changes:</p>
              <ul>
                <li>
                  <strong>Roccia</strong> added to the tier list (T1 Hybrid).
                  Roccia is a specialist focused on buffing specifically Havoc
                  Basic Attacks. This means Roccia, while strong, is dedicated
                  to a few specific teams the main ones being Camellya’s and
                  Havoc Rover’s. Unfortunately for Roccia, she faces stiff
                  competition in both from S6 Sanhua who does a similar job but
                  can also play within far more meta team compositions. As a
                  result of this, Roccia lands herself in T1 for now. With that
                  said it’s worth noting Roccia is an AoE gathering monster -
                  endgame is currently very single target focused but in the
                  future that may change. When that happens Roccia is likely to
                  ascend the tiers as she gains an edge over her competitors.
                </li>
                <li>
                  <strong>Yangyang</strong> T2{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T1.5. Yangyang has seen a substantial increase in use recently
                  - thanks to the addition of Carlotta, who she's proven to
                  perform strongly with being one of the best F2P options for
                  her. This on top of her previous viability in quickswap teams
                  with either Xiangli Yao or Changli has earned her an increase
                  in rating.
                </li>
                <li>
                  <strong>Mortefi</strong> T0.5{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1. Mortefi has been at the top of our tier list since 1.0 and
                  for good reason - when combined with Jiyan he formed the
                  backbone of one of the strongest teams which still stands
                  today. However with so many diverse damage dealers, and more
                  meta viable teams than ever available, Mortefi still only
                  works at the highest level with Jiyan. For this reason we’re
                  lowering his tier for now, but expect him to be right back
                  when we get more Heavy Attackers for him to play with, which
                  should be soon.
                </li>
                <li>
                  Added Multirole tag to <strong>Changli</strong>. Added to
                  better reflect her preferable playstyle with more casual
                  players who aren't necessarily going to quickswap often.
                </li>
              </ul>
              <h6>02/01/2025</h6>
              <p>List of changes:</p>
              <ul>
                <li>
                  <strong>Carlotta</strong> has been added to the tier list. The
                  numbers and testing we’ve done shows that she’s one of the
                  best DPS characters in the game who is also very easy to use.
                  As a result we decided to put her immediately into the T0 row.
                </li>
                <li>
                  <strong>Zhezhi</strong> T0.5{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0. Already argued by some as being that strong prior to
                  Carlotta’s release, Zhezhi proved herself in version 2.0 as
                  the best meta Hybrid option for arguably the two best DPS
                  characters in the game — Jinhsi and Carlotta. Her brand new
                  Jump Cancel tech found by our theorycrafting team additionally
                  optimizes her into a Hybrid with a very short rotation - if
                  players are willing to push Zhezhi to her limits. We’re moving
                  her up to T0 to reflect how wanted she is by the top team
                  compositions in the game along with her impressive base
                  strength.
                </li>
                <li>
                  <strong>Encore</strong> T1{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T0.5. After further testing from our theorycrafting team,
                  we’ve found out Encore’s single-target DPS matches Jiyan’s -
                  who’s present in the tier previously above hers. Both able to
                  be optimized, but also being more than playable in their best
                  teams at a casual level - in either case their single-target
                  DPS ends up matching as evenly as it gets. Their only
                  difference is that Encore is exclusively single-target while
                  Jiyan has huge AoE capabilities. This means Jiyan remains
                  overall slightly stronger, but very comparable to her.
                </li>
                <ul>
                  <li>
                    In this logic, we’re removing the Expert tag on Encore and
                    moving her up to T0.5 — her optimized single-target DPS
                    matches the characters in that tier, but we deem the tag
                    unnecessary as it’s not present on Jiyan. And Encore’s best
                    team is in actuality quite easy to play at a casual level -
                    where she still deals about as much DPS as a casual level
                    Jiyan, justifying her moving up.
                  </li>
                </ul>
                <li>
                  <strong>Rover (Havoc)</strong> T1.5{' '}
                  <span className="green">
                    <FontAwesomeIcon icon={faArrowUp} width="18" />
                  </span>{' '}
                  T1. After a bunch of optimization both to Havoc Rover and
                  their best support - Danjin, we’ve found out their DPS, even
                  at a casual level, is certainly lagging behind the T0.5
                  characters, but not enough to justify a big gap between Havoc
                  Rover and the high tier DPS characters like Encore, Jiyan and
                  Xiangli Yao. To reflect the big DPS increases our new
                  discoveries caused Havoc Rover to obtain, we’re moving them up
                  to T1 to more accurately reflect the surprisingly impressive
                  amount of damage they can deal. Keep in mind their position is
                  still subject to change once we can acquire their last 3
                  Sequences, which might push them up even higher.
                </li>
                <li>
                  <strong>Youhu</strong> T1{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T1.5. After further testing on our end, we’ve found out Youhu
                  holds a very small niche in the meta — only being a good
                  Support option if you don’t have Shorekeeper on your account
                  (as pretty much every account should have Verina and Baizhi by
                  now). Lagging behind at least slightly in regards to all three
                  generalist healer girls mentioned prior, she treads a very
                  fine line between remaining relevant and going completely
                  under the radar. Judging as she’s still quite far from a
                  terrible character, we’re moving her down to T1.5 to reflect
                  her situation.
                </li>
                <li>
                  <strong>Lumi</strong> T2{' '}
                  <span className="red">
                    <FontAwesomeIcon icon={faArrowDown} width="18" />
                  </span>{' '}
                  T3. We were already hesitant on placing Lumi in T3 on her
                  release, but with further testing, we’ve found out she holds
                  no realistic meta niche and her meta utility is comparable to
                  that of Taoqi’s. We tried making some Main DPS Lumi teams
                  work, but they had and still have no commendable success
                  whatsoever. Unfortunately lagging behind pretty much any other
                  options, she’s being moved in the bottom tier to illustrate
                  that. We will see if she will increase her value with
                  Carlotta's release as she can be used there as a budget
                  replacement to Zhezhi, but for now she will stay in T3.
                </li>
                <li>
                  Moved <strong>Yuanwu</strong> to the Support category.
                  Yuanwu’s only realistic use case was as a budget option for
                  Jinhsi teams. Outside of that, he holds no niche in the meta,
                  explaining his tier placement. Following this logic, as Yuanwu
                  is always sporting the Rejuvenating Glow set and a 3 star
                  Originite weapon as it’s most optimal on him in Jinhsi teams,
                  we think he currently fits a Support more so than a Hybrid.
                </li>
                <li>Added new tags to multiple characters.</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
        <div className="tier-list-header">
          <p className="title">
            Tier List{' '}
            <span>
              ({sortOption === 'toa' && 'Tower of Adversity'}
              {sortOption === 'ww' && 'Whimpering Wastes'})
            </span>
          </p>
          <p className="sub-title">
            You're currently viewing the{' '}
            <strong>
              {sortOption === 'toa' && 'Tower of Adversity'}
              {sortOption === 'ww' && 'Whimpering Wastes'}
            </strong>{' '}
            tier list. It shows{' '}
            {sortOption === 'toa' &&
              'how the character performs in the Tower of Adversity endgame mode.'}
            {sortOption === 'ww' &&
              'how the character performs in the Whimpering Wastes endgame mode.'}
            Use the switcher below to view a different tier list.
          </p>
        </div>
        <div className="tier-list-switcher">
          <div
            className={`option toa ${sortOption === 'toa' && 'selected'}`}
            onClick={() => setSortOption('toa')}
          >
            <p>Tower of Adversity</p>
          </div>
          <div
            className={`option ww ${sortOption === 'ww' && 'selected'}`}
            onClick={() => setSortOption('ww')}
          >
            <p>Whimpering Wastes</p>
          </div>
        </div>
        <div className="employees-filter-bar ww">
          <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
        </div>
        <div className="custom-tier-list-ww">
          <div className="custom-tier-header">
            <div className="tier-rating">
              <span>&nbsp;</span>
            </div>
            <div className="custom-tier-container">
              <div className="custom-header dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-header debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-header support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
            </div>
          </div>
          <div className={`meta-line`}>
            <h5 className="meta-header apex">
              <FontAwesomeIcon icon={faAnglesDown} width="18" /> Apex characters{' '}
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-0 first">
            <div className="tier-rating t-0">
              <span>T0</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 11)
                  .filter((emp) => emp[sortOption + '_cat'] === 'dps')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 11)
                  .filter((emp) => emp[sortOption + '_cat'] === 'hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 11)
                  .filter((emp) => emp[sortOption + '_cat'] === 'support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-05">
            <div className="tier-rating t-05">
              <span>T0.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 10)
                  .filter((emp) => emp[sortOption + '_cat'] === 'dps')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 10)
                  .filter((emp) => emp[sortOption + '_cat'] === 'hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 10)
                  .filter((emp) => emp[sortOption + '_cat'] === 'support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={`meta-line`}>
            <h5 className="meta-header meta">
              <FontAwesomeIcon icon={faAnglesDown} width="18" /> Meta characters{' '}
              <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-1">
            <div className="tier-rating t-1">
              <span>T1</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 9)
                  .filter((emp) => emp[sortOption + '_cat'] === 'dps')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 9)
                  .filter((emp) => emp[sortOption + '_cat'] === 'hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 9)
                  .filter((emp) => emp[sortOption + '_cat'] === 'support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-15">
            <div className="tier-rating t-15">
              <span>T1.5</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 8)
                  .filter((emp) => emp[sortOption + '_cat'] === 'dps')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 8)
                  .filter((emp) => emp[sortOption + '_cat'] === 'hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 8)
                  .filter((emp) => emp[sortOption + '_cat'] === 'support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={`meta-line`}>
            <h5 className="meta-header niche">
              <FontAwesomeIcon icon={faAnglesDown} width="18" /> Niche
              characters <FontAwesomeIcon icon={faAnglesDown} width="18" />
            </h5>
          </div>
          <div className="custom-tier tier-2">
            <div className="tier-rating t-2">
              <span>T2</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 7)
                  .filter((emp) => emp[sortOption + '_cat'] === 'dps')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 7)
                  .filter((emp) => emp[sortOption + '_cat'] === 'hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 7)
                  .filter((emp) => emp[sortOption + '_cat'] === 'support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="custom-tier tier-3">
            <div className="tier-rating t-3">
              <span>T3</span>
            </div>
            <div className="custom-tier-container">
              <div className="burst-type-mobile dps">
                <FontAwesomeIcon icon={faHandFist} width="18" /> DPS
              </div>
              <div className="custom-tier-burst dps">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 6)
                  .filter((emp) => emp[sortOption + '_cat'] === 'dps')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile debuffer">
                <FontAwesomeIcon icon={faVirus} width="18" /> Hybrid
              </div>
              <div className="custom-tier-burst debuffer">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 6)
                  .filter((emp) => emp[sortOption + '_cat'] === 'hybrid')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
              <div className="burst-type-mobile support">
                <FontAwesomeIcon icon={faFlask} width="18" /> Support
              </div>
              <div className="custom-tier-burst support">
                {visibleCharacters
                  .filter((emp) => emp[sortOption + '_rating'] === 6)
                  .filter((emp) => emp[sortOption + '_cat'] === 'support')
                  .sort((a, b) => (a.slug > b.slug ? 1 : -1))
                  .map((emp, index) => {
                    return (
                      <span key={index}>
                        <Card className="avatar-card">
                          <WWCharacter
                            slug={emp.slug}
                            tags={
                              sortOption === 'toa' ? emp.toa_tags : emp.ww_tags
                            }
                            tier={sortOption}
                            mode="icon"
                            showTags
                            showIcon
                            enablePopover
                            showEidolon
                            showTierListchange
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
      </div>
    </DashboardLayout>
  );
};

export default WWTierList;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Wuthering Waves | Prydwen Institute"
    description="The best tier list for Wuthering Waves that rates all available characters by their performance in the Tower of Adversity. Find the best characters in the game!"
    game="ww"
  />
);
